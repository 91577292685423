/* For CarouselContainer.js   */
.carousel-content {
  font-size: 3rem;
  font-weight: bold !important;
  position: absolute;
  left: 12%;
  top: 30vh;
  z-index: 20;
  width: 77vw;
  color: white;
  text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.7);
}

.main-content .custom-nav {
  height: 10px;
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 40%;
}

.main-content .custom-nav .owl-prev,
.main-content .custom-nav .owl-next {
  position: absolute;
  height: 45px;
  width: 45px;
  color: inherit;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  border: none;
  z-index: 100;
}
.main-content .custom-nav .owl-prev {
  left: 5%;
}
.main-content .custom-nav .owl-next {
  right: 5%;
}
.main-content .custom-nav .owl-prev span,
.main-content .custom-nav .owl-next span {
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
}
.main-content .custom-nav .owl-prev span {
  left: 40%;
  top: 37%;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
}
.main-content .custom-nav .owl-next span {
  right: 40%;
  top: 37%;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
}
.main-content .custom-dots {
  height: 40px;
  text-align: center;
  margin-top: 1rem;
}
.main-content .custom-dot {
  display: inline-block;
  background-color: #dddddd;
  height: 12px;
  width: 12px;
  border: none;
  border-radius: 50%;
  margin: 5px 7px;
}

.main-content .custom-dots .custom-dot:hover,
.main-content .custom-dots .custom-dot.active {
  background-color: #374785;
}

.item img {
  object-fit: cover;
  height: 100vh;
}

.arrow {
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.arrow span {
  display: block;
  width: 25px;
  height: 25px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  margin: -10px;
  animation: scroll-arrow 2.5s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes scroll-arrow {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

/* for Sponser.js*/
.sponser-container{
  background-attachment: fixed;
}
.sponser-logo {
  width: 10vw;
  margin-top: auto;
  margin-bottom: auto;
}

.imageContainer {
  width: 90vw;
  height: 65vh;
  background-color: black;
}

/* for OverlayText */
.img-container {
  width: 45vw;
  height: 90vh;
  position: relative;
}

.k {
  width: 55%;
  max-width: 100%;
  box-shadow: 0 1.5rem 4rem rgb(0, 0, 0, 0.4);
  border-radius: 10px;
  object-fit: cover;
}
.k1,
.k2,
.k3 {
  transition: transform 0.5s ease;
  position: absolute;
}

.k3 {
  top: 25vh;
  left: 2vw;
  z-index: 5;
}
.k1:hover,
.k2:hover,
.k3:hover {
  transform: scale(1.15);
  z-index: 6;
}

.k2 {
  left: 14.5vw;
  top: 5vw;
  z-index: 4;
}

.img-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay span {
  font-size: 1.5rem;
  position: absolute;
  top: 20%;
  left: 53%;
  transform: translateX(-50%);
  color: #fff;
}

.khanan-overlay {
  background-image: url(./image/khanan-bg.jpg) !important;
  background-attachment: fixed;
  transform: skewY(-4deg);
  z-index: -10;
}
.khanan-content {
  transform: skewY(4deg);
}
.khanan-bt {
  margin-left: 10rem;
}

/* for IndInfoSlider */
.iss.card {
  height: 70vh;
  border: none;
  border-radius: 20px;
  transition: transform 0.25s ease-in-out;
  overflow: hidden;
}
.iss-btn {
  position: absolute;
  bottom: 10px;
}
.iss .card-img-top {
  height: 40vh;
  width: auto;
  object-fit: cover;
  border-radius: 20px;
}

/* for Youtube.js */
.yt-player-wrapper {
  height: 450px;
}

/* COUNTDOWN */
.counter span {
  width: 20vw;
  font-size: 35px;
  color: #C5C5C5;
}


/* For Sme.js */
.sme {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.45)
    ),
    url("./image/sme-bg.jpg");

  height: 95vh;
  max-width: 100vw;
  background-position: bottom;
  background-size: cover;
  z-index: -10;
  clip-path: polygon(0 0, 0 100%, 60% 100%, 100% 60%, 100% 0);
}

.int_cont {
  width: 40vw;
  height: 40vh;
}
.int_logo {
  width: 14%;
  height: auto;
  margin-left: 80vw;
  transform: translateY(-101%);
}
.sme-content {
  z-index: 1000;
  padding-top: 7rem;
  padding-left: 2rem;
  background-image: radial-gradient(rgb(231, 225, 225), var(--sme-blue));
  color: transparent;
  background-clip: text;
}
.bt-sme {
  background-color: var(--sme-blue);
  color: #fff;
  font-size: 1.3rem;
  margin-top: 50vh;
  margin-left: 40vw;
}
.bt-sme:hover {
  color: #fff !important;
}
.bt-sme::after {
  background-color: var(--sme-blue);
}

/* Media Queries */
@media only screen and (max-width: 1480px) {
  .int_logo {
    transform: translateY(-120%);
  }
}

@media only screen and (max-width: 992px) {
  .sponser-logo {
    width: 12vw;
  }
  .k {
    width: 100%;
    height: 45%;
  }

  .k2 {
    top: 15vh;
    left: 30vw;
  }
  .k3 {
    top: 50vh;
    left: 10vw;
  }
}

@media only screen and (max-width: 767px) {
  .sponser-logo {
    width: 20vw;
  }
  .img-container img {
    width: 90vw;
    margin: auto;
  }
  .overlay span {
    font-size: 2rem;
  }
  .youtube {
    height: 27rem;
    width: 54rem;
  }
  .k {
    height: 40%;
  }
  .k2 {
    top: 25vh;
    left: 30vw;
  }
  .k3 {
    top: 50vh;
  }
  .int_logo {
    width: 21%;
    height: auto;
    margin-left: 77vw;
    transform: translateY(-240%);
  }
}

@media only screen and (max-width: 576px) {
  .carousel-content {
    top: 20vh;
    font-size: 2.5rem;
  }
  .sponser-logo {
    width: 30vw;
  }
  .int_logo {
    width: 20%;
    height: auto;
    margin-left: 77vw;
    margin-bottom: 25vh;
    transform: translateY(-200%);
  }
  .bt-sme {
    margin-top: 30vh;
  }
}

@media only screen and (max-width: 426px) {
  .bt-white {
    font-size: 1 rem;
    width: fit-content;
    margin-left: 25vw;
  }
  .bt-sme {
    margin-top: 20vh;
    margin-left: 20vw;
  }
}
