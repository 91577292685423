/* for About.js*/
.about-heading-bg {
  background: url("./image/about_us.svg") no-repeat;
  background-size: contain;
  background-position: center;
  height: 80vh;
}

.bodyX {
  background-image: url("./image/achieve.jpg");
  background-attachment: fixed;
  background-size: cover;
}

.about-head-icons {
  font-size: 5rem;
  color: #374785;
}

.about-wrap {
  position: relative;
  border-radius: 0.75rem;
}

.about-wrap::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url("../logo.png");
  background-repeat: no-repeat;
  background-size: 50vw 50vh;
  background-position-x: center;
  background-position-y: 30vh;
  background-attachment: fixed;
  z-index: 1;
}

.list-bullet-icon {
  transform: rotate(90deg);
}
