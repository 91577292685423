.ind-info-heading-bg {
  background: url("./ind_info.svg") no-repeat;
  background-size: contain;
  background-position: center;
  height: 80vh;
}
.latest {
  flex-direction: column !important;
}
.latest img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}

.old {
  margin-top: 5rem;
}
.old img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

/* quotes */
.quotesInfo {
  color: rgb(55, 71, 133);
}
.quotesInfo:before {
  content: open-quote;
  font-size: 2rem;
  color: #374785;
  opacity: 0.6;
}
.quotesInfo:after {
  content: close-quote;
  font-size: 2rem;
  color: #374785;
  opacity: 0.6;
}