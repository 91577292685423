.GH-intro {
  background: url(./BackgroundGallery.jpg) no-repeat;
  max-width: 100vw;
  padding-bottom: 10vh;
  padding-top: 9vh;
  background-size: cover;
  background-position: bottom;
  position: relative;
  background-attachment: fixed;
}
.GHImage1 {
  height: 350px;
  width: 300px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
  overflow: hidden;
  animation-name: GUI1Move;
  animation-duration: 2s;
}
@keyframes GUI1Move {
  0% {
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    transform: rotate(-5deg);
    opacity: 1;
  }
}

.GH {
  transition: transform 0.5s;
}
.GH:hover {
  transform: scale(1.1);
}
.GHText {
  margin-top: 5vw;
}
.GHText1 {
  text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.7);
  animation-name: GUIMoveLeft;
  animation-duration: 2s;
}
@keyframes GUIMoveLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.GHText2 {
  margin-top: 2vw;
  text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.7);
  animation-name: GUIMoveRight;
  animation-duration: 2s;
}
@keyframes GUIMoveRight {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.Imagetext {
  text-align: center;
}
.GalleryAllContainer {
  /* display: inline-block; */
  margin-bottom: 10px;
  overflow: hidden;
}

.GalleryAllSize {
  width: 30vw;
  height: auto;
  margin: 10px auto;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  object-fit: fill;
}
.GalleryAllSize:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
}
.GalleryAllSize.all:hover {
  filter: none;
  -webkit-filter: grayscale(0);
}
.GalleryAllSize.all {
  filter: grey;
  -webkit-filter: grayscale(1);
}
.wholeContainer {
  overflow-x: hidden;
}
.container1 {
  column-count: 3;
  padding: 20px;
  max-width: 100%;
  overflow-x: hidden;
}

@media screen and (max-width: 992px) {
  .container1 {
    column-count: 2;
  }
  .GalleryAllSize {
    width: 40vw;
  }
}
@media screen and (max-width: 576px) {
  .container1 {
    column-count: 1;
  }
  .GalleryAllSize {
    width: 80vw;
  }
}
