@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Libre+Baskerville:wght@400;700&display=swap");
/* Khanan */
/* font-family: "Merienda", cursive; */
/* Heading */
/* font-family: "Kaushan Script", cursive; */
/* Our Team */
/* font-family: "Arima Madurai", cursive; */
/* Remaining content */
/* font-family: "Libre Baskerville", serif; */
/*new 
:root {
  --sme-blue: #1989ca;
  --facebook-color: #4267b2;
  --instagram-color: #ea2840;
  --linkedin-color: #0077b5;
  --youtube-color: #ff0000;
}

html {
  padding-top: 82px;
}

html,
body {
  max-width: 100%;
  height: 100%;
  color: #252f36;
  font-family: "Libre Baskerville", serif;
}

body {
  background-color: #f7f7f7;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1 {
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 450;
  background-image: linear-gradient(to right, #081b66, #8899dd, #081b66);
  color: transparent;
  letter-spacing: 0.2rem;
  background-clip: text;
  font-family: "Kaushan Script", cursive;
}

h1:first-child::after {
  display: block;
  height: 2px;
  background-color: var(--sme-blue);
  content: " ";
  width: 100px;
  margin: 0 auto;
  margin-top: 20px;
}

h1::after {
  display: block;
  height: 0px;
  background-color: var(--sme-blue);
  content: " ";
  width: 100px;
  margin: 0 auto;
  margin-top: 20px;
}

.page-heading {
  font-size: 5rem;
  color: #374785;
  background: rgba(247, 247, 247, 0.8);
  width: 100vw;
}
h1.page-heading::after {
  content: none;
}

.heading {
  font-size: 2.5rem;
  margin-top: 4%;
  padding: auto;
}

.content {
  font-size: 1.25rem;
  line-height: 2rem;
  margin-top: 4%;
}

.app {
  min-height: 100vh;
}

.social-facebook,
.social-instagram,
.social-linkedin,
.social-youtube {
  color: #fff;
  transition: color 0.25s ease;
}

.social-facebook:hover {
  color: var(--facebook-color) !important;
}

.social-instagram:hover {
  color: var(--instagram-color) !important;
}

.social-linkedin:hover {
  color: var(--linkedin-color) !important;
}

.social-youtube:hover {
  color: var(--youtube-color) !important;
}

/* For scroll to top button */
.scroll-to-top .top {
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  background-color: #1989ca;
  z-index: 10;
  width: 60px;
  text-align: center;
  height: 60px;
  line-height: 45px;
  border-radius: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding-top: 5px;
}

.scroll-to-top .top:hover {
  color: #1989ca;
  background-color: white;
  box-shadow: 0 0 10px 5px #1989ca;
}

/* For reactstrap alert close button */
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  opacity: 1;
  text-decoration: none;
}

/* Pill Tabs Navigation */
.nav-tabs {
  border: 0;
}
.nav-tabs .nav-link.tabs-navi {
  margin-bottom: 0;
  background: 0 0;
  border: 1px solid #374785;
  color: #374785 !important;
  border-radius: 50px;
  transition: all 0.3s ease;
}
.nav-tabs .nav-link.tabs-navi:hover {
  background-color: rgb(55, 71, 133);
  color: white !important;
  text-decoration: none;
  cursor: pointer;
}
.nav-tabs .nav-link.tabs-navi.active {
  background-color: rgb(55, 71, 133);
  color: white !important;
}

/*For Header.js */
.navbar {
  font-size: 1.25rem;
}

.social-nav {
  font-size: 1.75rem;
}

.navbar-brand img {
  min-height: 40px;
  max-height: 60px;
  height: 4.5vw;
}

.solid-navbar {
  background: rgb(55, 71, 133);
  transition: background 0.5s ease 0s;
}

.transparent-navbar {
  background: transparent !important;
  transition: background 0.5s ease 0s;
}

.nav-link {
  color: #fff !important;
  transition: color 0.3s ease 0s;
}

a.nav-dropdown {
  text-decoration: none;
}

.nav-dropdown .dropdown-item {
  color: #fff;
}

.nav-dropdown .dropdown-item:hover {
  color: var(--sme-blue);
  text-decoration: none;
}

.nav-link:hover {
  color: var(--sme-blue) !important;
}

.dropdown-menu {
  background-color: #4255a0 !important;
}

.navbar-toggler {
  margin-right: 20px;
  color: rgba(255, 255, 255, 0.6) !important;
  border: 3px solid;
  border-color: rgba(255, 255, 255, 0.6) !important;
}

.navbar-toggler:hover {
  box-shadow: 0 0 0 0.25rem;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler-icon {
  filter: invert(100%);
}

/* Custom button */
.bt:link,
.bt:visited {
  text-decoration: none;
  padding: 10px 20px;
  display: inline-block;
  transition: all 0.2s;
  position: relative;
  border-radius: 100px !important;
}

.bt-white {
  background-color: #fff;
  color: rgba(0, 0, 0, 1);
  font-size: 1.25rem;
  margin-top: 3rem;
  font-weight: 400;
}
.bt-white:hover {
  color: rgba(0, 0, 0, 1) !important;
}
.bt-blue {
  background-color: #374785;
  color: #fff;
  font-size: 1.25rem;
  margin-top: 1rem;
}
.bt-blue:hover {
  color: #fff !important;
}

.bt:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}
.bt:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}
.bt::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
  transition: all 0.4s;
}
.bt-white::after {
  background-color: #fff;
}
.bt-blue::after {
  background-color: #374785;
}
.bt:hover::after {
  transform: scaleX(1.5) scaleY(1.4);
  opacity: 0;
}

/* for Footer.js */
.footer {
  background-color: rgb(55, 71, 133);
  position: sticky;
}

.copyright {
  background-color: rgba(0, 0, 0, 0.2);
}

.icon-container {
  margin-top: 20px;
  width: 100%;
  height: 50px;
}

.icon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 30px;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
}

.icon::before,
.icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.25s ease-in-out;
  border-radius: 30px;
}
.icon .icon-tag {
  position: relative;
  color: #ffffff;
  font-size: 30px;
  margin-top: 5px;
  transition: all 0.25s ease-in-out;
}

.icon:hover,
.icon:focus {
  transform: translateY(-5px) scale(1.25);
}

.icon-fill-facebook::before {
  transition-duration: 0.5s;
  box-shadow: inset 0 0 0 1px var(--facebook-color);
}
.icon-fill-facebook:hover::before {
  box-shadow: inset 0 0 0 60px var(--facebook-color);
}

.icon-fill-instagram::before {
  transition-duration: 0.5s;
  box-shadow: inset 0 0 0 1px var(--instagram-color);
}
.icon-fill-instagram:hover::before {
  box-shadow: inset 0 0 0 60px var(--instagram-color);
}

.icon-fill-linkedin::before {
  transition-duration: 0.5s;
  box-shadow: inset 0 0 0 1px var(--linkedin-color);
}
.icon-fill-linkedin:hover::before {
  box-shadow: inset 0 0 0 60px var(--linkedin-color);
}

.icon-fill-youtube::before {
  transition-duration: 0.5s;
  box-shadow: inset 0 0 0 1px var(--youtube-color);
}
.icon-fill-youtube:hover::before {
  box-shadow: inset 0 0 0 60px var(--youtube-color);
}

@media only screen and (max-width: 767px) {
  .heading {
    font-size: 2rem;
  }
  .content {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 576px) {
  .page-heading {
    font-size: 3.5rem;
  }
}
