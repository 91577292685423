@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@400;700&display=swap");
/* font-family: 'Arima Madurai', cursive; */

.our-team {
  font-family: "Arima Madurai", cursive;
}

.team-heading-bg {
  background: url("./images/our_team.svg") no-repeat;
  background-size: contain;
  background-position: center;
  height: 80vh;
}

/* for OurTeam*/
.team.card {
  border: none;
  border-radius: 20px;
  transition: transform 0.25s ease-in-out;
  overflow: hidden;
}

.team.card:hover {
  border: 1px dashed rgba(55, 71, 133, 1);
  transform: scale(1.05);
}

.team .card-img-top {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
}

.team-social-handles {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3rem;
  transform: translate(1500px, -50%);
  z-index: 10;
  transition: 0.6s;
  transition-timing-function: linear;
}

.team.card:hover .team-social-handles {
  transform: translate(-50%, -50%);
  transition-timing-function: ease;
}

.team.card::after {
  content: "";
  width: 800px;
  height: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-140%, -50%);
  z-index: 1;
  background: rgba(55, 71, 133, 0.8);
  transition: 0.8s;
  border-radius: 50%;
}
.team.card:hover::after {
  transform: translate(-50%, -50%);
}
