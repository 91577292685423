*,
*:before,
*:after {
  box-sizing: border-box;
}

.knavi {
  float: left;
  position: fixed;
  top: 30%;
  left: 0;
  background: transparent;
  padding-inline-start: 0px !important;
  transform: translateX(-30px);
  z-index: 1000;
}

.knavi ul {
  text-align: center;
  list-style-type: none;
}

.knavi ul li {
  width: 50px;
  height: 10vh;
  cursor: pointer;
  background: linear-gradient(45deg, #ff0881, #6827b0);
  text-transform: uppercase;
  display: block;
  color: transparent;
  transition: all 0.5s ease;
  text-decoration: none;
}

.knavi ul li span {
  display: block;
}

.knavi ul li:hover {
  width: 130px;
  height: 15vh;
  color: #fff;
}
a.knav-image {
  width: 100%;
  height: 100%;
  color: white;
}
a.knav-image span {
  color: transparent;
  height: 0;
  transition: all 0.2s ease;
}
a.knav-image:hover span {
  width: 130px;
  height: 15vh;
  color: #fff;
}
.knav-content {
  padding-top: 25px;
}

span,a{
  text-decoration: none;
}